<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
     <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
    <ServerError  />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title class=" elevation-1">
            Edit Admin
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="profileForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="firstname"
                      label="First Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="lastname"
                      label="Last Name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="username"
                      label="Username"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="accounttype"
                      label="Account Type"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="country"
                      label="Country"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="phoneRules"
                      v-model="phone"
                      label="Phone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="emailRules"
                      v-model="email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="type"
                      label="Role"
                    ></v-text-field>
                  </v-col> -->
                </v-row>
                <v-row class="justify-center">
                  <v-btn
                    :disabled="!valid"
                    outlined
                    color="red"
                    @click="validate()"
                  >
                    Update
                  </v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import store from './../../store'
export default {
  props: ["adminid"],
  data() {
    return {
      ServerError: false,
      appLoading: false,
      showsnackbar: false,
      firstname: null,
      lastname: null,
      username: null,
      accounttype: null,
      country: null,
      phone: null,
      email: null,
      type: null,
      msg: null,
      valid: true,
      //   dateRules:[
      //     (v) => !!v || "Date of Birth is required",
      //       (v)=>/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/.test(v) || "Date of Birth must be date"
      //   ],
      Rules: [(v) => !!v || "Required"],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      emailRules: [
        (v) => !!v || "Email is Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone Number is Required",
        (v) => (v && v.length <= 10) || "Number must be less than 11",
        (v) => (v && v.length >= 10) || "Number must be grater than 10",
        (v) => /^\d{10}$/.test(v) || "Phone must be number",
      ],
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    validate() {
      if (this.$refs.profileForm.validate()) {
        this.editAdmin();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/user/info",
        method: "GET",
        params: {
          id: this.adminid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          var user = response.data.data;
          this.firstname = user.firstname;
          this.lastname = user.lastname;
          this.username = user.username;
          this.phone = user.phone;
          this.email = user.email;
          this.type = user.type;
          this.accounttype = user.accounttype;
          this.country = user.country;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editAdmin() {
      this.appLoading = true;
      var user = {};
      user["firstname"] = this.firstname;
      user["lastname"] = this.lastname;
      user["username"] = this.username;
      user["phone"] = this.phone;
      user["email"] = this.email;
      user["type"] = this.type;
      user["accounttype"] = this.accounttype;
      user["country"] = this.country;
      user["id"] = this.adminid;
      axios({
        method: "POST",
        url: "/user/AdminEdit",

        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            store.commit('userDatafun',response.data.data)
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.$router.go(-1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>